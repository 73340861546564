





























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { SaveMotivosDeCancelamentoUseCase } from '@/usecases'
import type { MotivoCancelamento } from '@/models'

@Component

export default class DialogoDeEdicaoDeMotivosDeCancelamento extends Vue {
	@Prop({type: Object}) motivo?: MotivoCancelamento
	motivoEmEdicao: string | undefined
	mostra = false
	salvando = false
	saveMotivosDeCancelamentoUseCase = new SaveMotivosDeCancelamentoUseCase()

	mostrar() {
		this.onChangeMotivo()
		this.mostra = true
	}

	esconder() {
		this.motivoEmEdicao = undefined
		
		this.mostra = false
	}

	async salvar() {
		if (!this.motivo) return
		if (!this.motivoEmEdicao) return

		try {
			this.salvando = true

			this.editarMotivo()

			const motivoSalvo = this.motivo.id
				? await this.saveMotivosDeCancelamentoUseCase.update(this.motivo)
				: await this.saveMotivosDeCancelamentoUseCase.create(this.motivo)

			this.motivo.id
				? this.$emit('update', motivoSalvo)
				: this.$emit('create', motivoSalvo)
			this.mostra = false
			AlertModule.setSuccess('Motivo salvo com sucesso')
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
	}

	editarMotivo() {
		if(this.motivo && this.motivoEmEdicao) this.motivo.motivo = this.motivoEmEdicao
	}

	@Watch('motivo')
	onChangeMotivo() {
		if(this.motivo){
			this.motivoEmEdicao = this.motivo.motivo
		}
	}

}
